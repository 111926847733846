//import Link from "next/link";
import { Link, navigate } from "gatsby";
import React from "react";
import styled from "styled-components";
import IconArrowRight from "../../modules/IconArrowRight/iconarrowright";
import placeHolderImage from "../../../images/placeholders/placeholder.png";
import ImageLoader from "../../modules/ImageLoader/imageLoader";
const menneskene = "/assets/images/placeholders/menneskene.png";
const verdensbilder = "/assets/images/placeholders/verdensbilder.png";
const dyder = "/assets/images/placeholders/dyder.png";

const OuterContainer = styled.div`
    display: block;
    position: relative;
    overflow: hidden;
    box-sizing: border-box;
   // width: 100px;  
`;

const Container = styled.ul`
    display: flex;  
    padding-left: 0;
    list-style: none;
    
    // overrides for ul:
    margin-block-start: 0em;
    margin-block-end: 0em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 0px;
    flex-direction: column;
        //overflow-y: scroll;
        overflow-x: hidden;
    /*
    @media only screen and (max-width: 1023px){
        margin-left: calc(var(--marginLeftSm)*-1);
        margin-right: calc(var(--marginLeftSm)*-1);       
    }
    */
    /*
    @media only screen and (min-width: 1024px){
        flex-direction: column;
        //overflow-y: scroll;
        overflow-x: hidden;
        scroll-snap-type: y mandatory;
        //margin-bottom: 73px;
    }
    */

    // Show scrollbar on non-touch screens
    /*
    @media (hover: none){
        scrollbar-width: none;
        &::-webkit-scrollbar {
            display: none;
        }
        -webkit-overflow-scrolling: touch;
    }
    */

    @media (hover:hover){
        -webkit-transform:scale(1);        
    }
`;

const ImageContainer = styled.div`
    border-radius: 5px;
    //height: 134px;
    //width: 150px;
    height: 67.5px;
    width: 89.5275px;
    //object-fit: cover;
    flex-shrink: 0;
    
    @media only screen and (min-width: 1024px){
        height: 90px;
        width: 119.37px;
    }
`;

const ImageTextContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    
    //@media only screen and (min-width: 1024px){
    margin-left: 1.6em;
    //}    
`;

const ImageTitle = styled.h3`
    font-weight: 400;
    font-size: 1rem;
    color: #000;
    margin-top: 5px;
    margin-bottom: 0;
    letter-spacing: 0.36px;
    line-height: 1.6875rem;
    text-align: left;

    @media only screen and (min-width: 1024px){
        font-size: 1.125rem;
    }
`;

const ImageSubTitle = styled.p`
    font-weight: 400;
    font-size: 0.875rem;
    color: #000;
    margin-top: 0;
    line-height: 1.0625rem;
    letter-spacing: 0px;
    opacity: 0.5;
`;


const ArrowContainer = styled.div`
    //display: none;
    align-self: center;
    margin-left: auto;
    margin-right: 45px;
    
    @media only screen and (max-width: 1023px){
        display: none;
        margin-right: 10px;
    }
    
`;

const ListItem = styled.li`
    //flex-shrink: 1;
    //margin-left: 0;
    //padding-left: 0;
    &:last-of-type{
        //padding-right: 30px;
        @media only screen and (min-width: 1024px){            
            padding-right: 0px;
        }
    }

    &:not(:last-of-type) {
       // margin-right: 13px;
        
        //@media only screen and (min-width: 1024px){
        margin-bottom: 10px;
        margin-right: 0px;
        //}
    }
`;

const HorizontalLine = styled.div`
    display: block;
    width: calc(100% - 50px);
    height: 1px;
    background-color: var(--colorBgGray);    
    
    @media only screen and (max-width: 1023px){
        width: calc(100% - 15px);
    }
    
`;

const ElementContainer = styled.button`
    display: flex;
    //flex-direction: column;    
    scroll-snap-align: start;
    cursor: pointer;
    background: none;
    border: none;
    padding: 0;    
    width: 100%;
    &:hover ${ImageTitle} {
        text-decoration: underline;
    } 

    &:hover ${ArrowContainer} {
        opacity: 0.5;
    }
    /*
    &:first-of-type{
        //@media only screen and (max-width: 1023px){
        //margin-left: 30px; 
        }       
    }
    */
    

    
`;

interface CarouselProps {
    data: Array<{
        title: string,
        subTitle?: string,
        image: string,
        pageUrl: string,
        uuid: string
    }>;

}

const Carousel: React.FC<CarouselProps> = ({ data }) => {

    return (

        <Container >
            {/*console.log("carousel data: ", data)*/}

            {data.map((elem) =>
                <ListItem
                    data-testid="carousel-item"
                    key={elem.uuid}>
                    <ElementContainer
                        onClick={() => { elem.pageUrl === "refresh" ? open("/", "_self") : navigate(elem.pageUrl) }}
                        tabIndex={0}
                        aria-label={`${elem.subTitle}: ${elem.title}`}
                    >
                        <ImageContainer  >
                            <ImageLoader
                                imgUrl={elem.image || placeHolderImage}
                                alt={elem.title}
                                fitCover={true}
                                maxImages={false}
                                maxSize={400}
                                borderRadius={"5px"}
                            />
                        </ImageContainer>
                        <ImageTextContainer>
                            <ImageTitle data-testid="imageTitle">{elem.title}</ImageTitle>
                            <ImageSubTitle data-testid="imageSubTitle">{elem.subTitle}</ImageSubTitle>
                        </ImageTextContainer>

                        <ArrowContainer><IconArrowRight /></ArrowContainer>

                    </ElementContainer>
                    <HorizontalLine />
                </ListItem>
            )}
        </Container>
    );
}

export default Carousel;