import React, { useRef } from "react";
import { useContext } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { shortLangCode } from "../../common/helpers/helpers";
import Carousel from "../framework/Carousel/carousel";
import PageLoader from "../framework/PageLoader/pageLoader";
import ParagraphText from "../framework/ParagraphText/paragraphText";
import HeadingContainer from "../modules/HeadingContainer/headingContainer";
import { Store } from "../Store/store";

const MainExploreContainer = styled.div`
    
    display: flex;
    flex-direction: column;    
    flex: 1;       
    
    margin-top: 10px; //var(--marginTopSm);
    margin-left: var(--marginLeftSm);
    margin-right: var(--marginLeftSm); 

    @media only screen and (min-width: 1024px){
        margin-left: var(--marginLeftLg);
        margin-right: var(--marginLeftLg);
        margin-top: 10px;//var(--marginTopLg);        
    }
`;

const Heading = styled.h2`    
    font-weight: 700;
    font-size: 1.5625rem;
    line-height: 1.9375rem;
    letter-spacing: 0;
    color: #454F63;
    margin-top: 0;   
`;



const CustomMargin = styled.div<CustomMarginProps>`
    margin-bottom: ${props => props.margin};
`;

const EndMargin = styled.div`
    margin-bottom: 60px;
`;


interface CustomMarginProps {
    margin: string;
}

const MainExplore = () => {
    const { t, i18n } = useTranslation("common");
    const [data, setData] = useState([]);
    
    const { state, dispatch }: { state: any, dispatch: any } = useContext(Store);
    const zoneUuids = state.constants.zoneUuids;
    const [prefaceData, setPrefaceData]: [prefaceData: any, setPrefaceData: React.Dispatch<React.SetStateAction<object>>] = useState({});
    const setPrefaceDataRef = useRef(setPrefaceData);
    const [stopReaderAudio, setStopReaderAudio] = useState(false);

    const getAudioUrl = () => {
        let _audioUrl;
        try {
            _audioUrl = data.length > 0 && (shortLangCode(i18n.language) === "no" ? prefaceData.audio.norwegian.media.resources.mp3.url : prefaceData.audio.english.media.resources.mp3.url);
        }
        catch (err) {
            //console.log(err);
            _audioUrl = undefined;
        }
        finally {
            return _audioUrl;
        }
    }


    useEffect(() => {
        if(!state.data.settings){return}
        if (!Object.keys(state.data.settings).length) { return }
        setPrefaceDataRef.current(state.data.settings.content.preface);
    }, [state.data.settings]);

    useEffect(() => {
        if (!state.data.zones) {
            return;
        }
        setData(state.data.zones);
    }, [state.data.zones]);

    let _carouselData;
    if (data.every(el => el === undefined)) {
        _carouselData = [{ title: t("error.noDataError"), subTitle: "", image: undefined, pageUrl: "refresh", uuid: "uuid" }];
    } else {
        _carouselData = data.map((elem) => {

            const title = elem.content.title[`locale:${shortLangCode(i18n.language)}`];
            const subTitle = `${t("general.room")} ${elem.content.zone}`
            let image: string;
            try {
                image = elem.content.image.media.imageUrl  //: elem.imageUrl; //TEMPORARY FIX elem.imageUrl;
            }
            catch (err) {
                console.log("Error! Image not found. Trying alternative location: "/*, err*/);
                image = elem.imageUrl;
            }

            const pageUrl = `/room/${elem.content.zone}`
            const uuid = elem.uniqueId;
            return { title, subTitle, image, pageUrl, uuid }

        })
    }
    const carouselData = _carouselData;
    carouselData.sort((a, b) => {
        if (a.subTitle < b.subTitle) return -1;
        if (a.subTitle > b.subTitle) return 1;
        return 0;
    });


    return (
        <MainExploreContainer>
            
            {data.length ?
                <>
                    <HeadingContainer
                        soundUrl={getAudioUrl()}
                        headerType={"h1"}
                        stopReaderAudio={stopReaderAudio}
                        setStopReaderAudio={setStopReaderAudio}>{t("mainExplore.exploreExhibition")}</HeadingContainer>
                    <ParagraphText numChars={100} text={prefaceData.text ? prefaceData.text[`locale:${shortLangCode(i18n.language)}`] : ""} />
                    <CustomMargin margin="1em" />
                    {carouselData.length && carouselData[0].pageUrl !== "refresh" ?
                        <Carousel data={carouselData} />
                        :
                        <PageLoader showError={carouselData[0].pageUrl === "refresh"} />
                    }
                </>
                :
                <PageLoader />
            }
            <EndMargin />
            
            
        </MainExploreContainer>
    );
};

export default MainExplore;