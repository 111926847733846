
import React, { FC } from "react";
import MainExplore from "../components/MainExplore/mainExplore";
import PageWrapper from "../components/PageWrapper/pageWrapper";
import styled from 'styled-components';



const HomePage = () => {

  return (

    <PageWrapper isHomePage>
      <MainExplore></MainExplore>
    </PageWrapper>


  );
};

export default HomePage;